@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.LinkListDropdown';

#{$name} {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    width: 100%;

    &-image {
        position: relative;
        bottom: -20px;
    }

    &-textWrapper {
        @include media-breakpoint-down(xs) {
            text-align: center;
        }
    }

    &-title {
        font-size: 1.25rem;

        @include media-breakpoint-up(lg) {
            font-size: 2.375rem;
        }
    }

    &-intro {
        @include media-breakpoint-up(lg) {
            font-size: .985rem;
        }
    }

    // Bootstrap dropdown overrides
    .dropdown-menu {
        box-shadow: 0 5px 8px rgba(0,0,0,.2);
        border-radius: 5px;
        padding: 5px 0;

        .dropdown-item {
            padding: .75rem;
            border-bottom: 1px solid $gray-100;

            &:last-child {
                border: 0;
            }
        }
    }
}
